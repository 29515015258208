import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import Loadable from 'react-loadable';

/* webpackChunkName: "Users" */
const Users = Loadable({
  loader: () => import('./routes/users/all'),
  loading: TopBarProgress
});

/* webpackChunkName: "NewUser" */
const NewUser = Loadable({
  loader: () => import('./routes/users/new'),
  loading: TopBarProgress
});

/* webpackChunkName: "Services" */
const Services = Loadable({
  loader: () => import('./routes/services/all'),
  loading: TopBarProgress
});

/* webpackChunkName: "Services" */
const Deliveries = Loadable({
  loader: () => import('./routes/deliveries/all'),
  loading: TopBarProgress
});

const Orders = Loadable({
  loader: () => import('./routes/orders'),
  loading: TopBarProgress
});

/* webpackChunkName: "NewService" */
const NewService = Loadable({
  loader: () => import('./routes/services/new'),
  loading: TopBarProgress
});

/* webpackChunkName: "Payments" */
const Payments = Loadable({
  loader: () => import('./routes/payments'),
  loading: TopBarProgress
});

/* webpackChunkName: "Hubs" */
const Hubs = Loadable({
  loader: () => import('./routes/hubs/all'),
  loading: TopBarProgress
});

/* webpackChunkName: "NewHub" */
const NewHub = Loadable({
  loader: () => import('./routes/hubs/new'),
  loading: TopBarProgress
});

const Dash = () => (
  <Switch>
    <Route path="/users/all">
      <Users />
    </Route>
    <Route path="/users/new">
      <NewUser />
    </Route>
    <Route path="/services/all">
      <Services />
    </Route>
    <Route path="/services/new">
      <NewService />
    </Route>
    <Route path="/deliveries/all">
      <Deliveries />
    </Route>
    <Route path="/orders">
      <Orders />
    </Route>
    <Route path="/payments">
      <Payments />
    </Route>
    <Route path="/hubs/all">
      <Hubs />
    </Route>
    <Route path="/hubs/new">
      <NewHub />
    </Route>
    <Redirect to="/services/all" />
  </Switch>
);

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Dash);
