const firebaseConfig = {
  apiKey: 'AIzaSyDru_dH3ajvW0O7dIauIjetd5-Pqi5nXx0',
  authDomain: 'eugenio-969c3.firebaseapp.com',
  databaseURL: 'https://eugenio-969c3.firebaseio.com',
  projectId: 'eugenio-969c3',
  storageBucket: 'eugenio-969c3.appspot.com',
  messagingSenderId: '387793221919',
  appId: '1:387793221919:web:99f6d0419cd6a0c0c6d82a'
};
const secondConfig = {
  apiKey: 'AIzaSyDru_dH3ajvW0O7dIauIjetd5-Pqi5nXx0',
  authDomain: 'eugenio-969c3.firebaseapp.com',
  databaseURL: 'https://eugenio-969c3.firebaseio.com'
};

const rrfConfig = {
  userProfile: 'Usuarios',
  useFirestoreForProfile: true
};

export { firebaseConfig, rrfConfig, secondConfig };
