import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import Loadable from 'react-loadable';

/* webpackChunkName: "Users" */
const Users = Loadable({
  loader: () => import('./routes/drivers/all'),
  loading: TopBarProgress
});

/* webpackChunkName: "NewUser" */
const NewUser = Loadable({
  loader: () => import('./routes/drivers/new'),
  loading: TopBarProgress
});

/* webpackChunkName: "Services" */
const Services = Loadable({
  loader: () => import('./routes/services'),
  loading: TopBarProgress
});

/* webpackChunkName: "Services" */
const Orders = Loadable({
  loader: () => import('./routes/orders'),
  loading: TopBarProgress
});

/* webpackChunkName: "Services" */
const DriverBalance = Loadable({
  loader: () => import('./routes/payments/all'),
  loading: TopBarProgress
});

/* webpackChunkName: "Services" */
const Pay = Loadable({
  loader: () => import('./routes/payments/new'),
  loading: TopBarProgress
});

const History = Loadable({
  loader: () => import('./routes/payments/history'),
  loading: TopBarProgress
});

const Analytics = Loadable({
  loader: () => import('./routes/analytics'),
  loading: TopBarProgress
});

const Dash = () => (
  <Switch>
    <Route path="/drivers/all">
      <Users />
    </Route>
    <Route path="/drivers/new">
      <NewUser />
    </Route>
    <Route path="/services/all">
      <Services />
    </Route>
    <Route path="/orders">
      <Orders />
    </Route>
    <Route path="/analytics">
      <Analytics />
    </Route>
    <Route path="/payments">
      <Services />
    </Route>
    <Route path="/payment/drivers">
      <DriverBalance />
    </Route>
    <Route path="/payment/pay">
      <Pay />
    </Route>
    <Route path="/payment/history">
      <History />
    </Route>
    <Redirect to="/services/all" />
  </Switch>
);

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

export default connect(mapStateToProps)(Dash);
