import React from 'react';
import PropTypes from 'prop-types';
import theme from '@theme';
import GoogleMapReact from 'google-map-react';
import { google } from '@config/environment';
import Marker from './components/marker';

const defaultStyle = {
  width: '100%',
  height: 400
};

const Map = ({
  center,
  zoom,
  children,
  defaultCenter,
  defaultZoom,
  style,
  onGoogleApiLoaded,
  yesIWantToUseGoogleMapApiInternals,
  ...props
}) => (
  <div style={{ ...defaultStyle, ...style }} {...props}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: google.mapsApiKey, libraries: 'places' }}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      center={center}
      zoom={zoom}
      onGoogleApiLoaded={onGoogleApiLoaded}
      options={{
        styles: [
          {
            stylers: [
              {
                hue: theme.colors.primary
              }
            ]
          },
          {
            featureType: 'water',
            stylers: [
              {
                color: '#ffffff'
              }
            ]
          }
        ]
      }}
    >
      {children}
    </GoogleMapReact>
  </div>
);

const defaultZoom = 13;
const defaultCenter = {
  lat: 25.651434, // Default Lat for Tec de Monterrey
  lng: -100.2938946 // Default Lng for Tec de Monterrey
};

Map.defaultProps = {
  height: 400,
  width: '100%',
  zoom: defaultZoom,
  center: defaultCenter,
  defaultCenter,
  children: undefined,
  defaultZoom,
  style: {},
  onGoogleApiLoaded: () => {},
  yesIWantToUseGoogleMapApiInternals: true
};

Map.propTypes = {
  center: PropTypes.object,
  defaultCenter: PropTypes.object,
  zoom: PropTypes.number,
  style: PropTypes.object,
  defaultZoom: PropTypes.number,
  children: PropTypes.any,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onGoogleApiLoaded: PropTypes.func,
  yesIWantToUseGoogleMapApiInternals: PropTypes.bool
};

export { Marker };
export default Map;
