import React, { useEffect, useState } from 'react';
import { Input, Button, Typography, message } from 'antd';
import PublicLayout from '@layouts/public';
import moment from 'moment';
import Map, { Marker } from '@templates/map';
import { useFirebase } from 'react-redux-firebase';
import { ElementsContainer, Menu } from './elements';
import useQueryParam from './hooks';

const { Title, Paragraph } = Typography;
const { Search } = Input;

const Tracking = () => {
  const [db, setDb] = useState();
  const [service, setService] = useQueryParam('service');
  const [locationTrackerRef, setLocationTrackerRef] = useState();
  const [locationData, setLocationData] = useState();
  const [deliveryData, setDeliveryData] = useState();
  const firebase = useFirebase();

  useEffect(() => {
    if (firebase) {
      setDb(firebase.database());
    }
  }, [firebase]);

  const establishTracker = value => {
    let temp;
    if (value) {
      firebase
        .firestore()
        .collection('Services')
        .where('deliveryIds', 'array-contains', value)
        .get()
        // eslint-disable-next-line consistent-return
        .then(snapshot => {
          if (snapshot.empty) {
            message.warning('El código es inválido');
          }
          snapshot.forEach(doc => {
            const ref = db.ref(`serviceLocations/${doc.id}`);
            temp = doc.data();
            for (let i = 0; i < temp.deliveries.length; i++) {
              if (temp.deliveries[i].id === value) {
                setDeliveryData(temp.deliveries[i]);
              }
            }
            setLocationTrackerRef(ref);
            setService(doc.id);
          });
        });
    }
  };

  useEffect(() => {
    let temp;
    if (db && service) {
      firebase
        .firestore()
        .collection('Services')
        .where('deliveryIds', 'array-contains', service)
        .get()
        // eslint-disable-next-line consistent-return
        .then(snapshot => {
          if (snapshot.empty) {
            setLocationTrackerRef();
            setLocationData();
            setService('');
          }
          snapshot.forEach(doc => {
            temp = doc.data();
            for (let i = 0; i < temp.deliveries.length; i++) {
              if (temp.deliveries[i].id === service) {
                setDeliveryData(temp.deliveries[i]);
              }
            }
            establishTracker(doc.id);
          });
        });
    }
  }, [db]);

  useEffect(() => {
    if (locationTrackerRef) {
      locationTrackerRef.on('value', snapshot => {
        if (!snapshot.exists()) {
          setLocationTrackerRef();
          setLocationData();
          message.warning('El código es inválido');
          setService('');
        } else {
          const data = snapshot.val();
          setLocationData(data);
        }
      });
    }
  }, [locationTrackerRef]);
  return (
    <PublicLayout>
      <ElementsContainer>
        <Menu>
          <Title level={5}>Rastrea tu envío</Title>
          <Search
            placeholder="Código"
            disabled={locationData}
            defaultValue={service}
            onSearch={establishTracker}
            size="large"
            style={{ width: 350 }}
          />
          {locationData && (
            <>
              <Button
                onClick={() => {
                  setLocationData();
                  setService('');
                }}
                type="link"
              >
                Reset
              </Button>
              <Paragraph strong style={{ margin: 0 }}>
                Última actualización
              </Paragraph>
              <Paragraph>{moment(locationData.timestamp).format('lll')}</Paragraph>
              <Paragraph strong style={{ margin: 0 }}>
                Info de Pedido
              </Paragraph>
              <Paragraph>{deliveryData.name}</Paragraph>
              <Paragraph>{deliveryData.address}</Paragraph>
              <Paragraph>{deliveryData.status}</Paragraph>
            </>
          )}
        </Menu>
      </ElementsContainer>
      <Map
        center={
          locationData && { lat: locationData.coords.latitude, lng: locationData.coords.longitude }
        }
        style={{ height: '80vh', width: '100%' }}
      >
        {locationData && (
          <Marker lat={locationData.coords.latitude} lng={locationData.coords.longitude} />
        )}
      </Map>
    </PublicLayout>
  );
};

export default Tracking;
